import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import Cover from '../Cover';
// import Rating from '../Rating';
// import Progress from '../Progress';
import Typography from '../Typography';

const Container = styled.div`
  width: 250px;
  .name {
    margin-top: 8px;
  }
  ${Cover} {
    cursor: pointer;
    padding: 16px 64px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.g5};
  padding-top: 14px;
  font-size: 12px;
`;

export default function CardCover({
  img,
  title,
  category,
  rating,
  color,
  coverPosition,
  // progress = 0,
  locked = false,
  ...props
}) {
  return (
    <Container>
      {locked ? (
        <Cover
          title={title}
          color={color}
          img={img}
          coverPosition={coverPosition}
          {...props}
        >
          <Typography variant="h6" color="light">
            Saiba mais
          </Typography>
          <FontAwesomeIcon icon={faLock} />
        </Cover>
      ) : (
        <Cover
          title={title}
          color={color}
          img={img}
          coverPosition={coverPosition}
          {...props}
          justifyContent="center"
        >
          {/* <Progress color="#fff" height={6} progress={progress} outline /> */}
        </Cover>
      )}
      <Footer>
        <Typography variant="body3">{category}</Typography>
        {/* <Rating initialRating={rating} readonly /> */}
      </Footer>
      <div className="name">
        <Typography variant="body2">{title}</Typography>
      </div>
    </Container>
  );
}
